
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-file-attach-field {

  .errorBorder {
    border: 1px solid #CC1D26;
  }

  .blockIcon {
    font-size: 20px;
    width: 35px;
  }

  .blocktext {
    width: calc(100% - 100px);
    overflow-wrap: break-word;
    cursor: pointer;
  }

  .sizeFile {
    width: 65px;
    text-align: end;
  }

  & &-btn {
    width: 100%;
    margin-top: 12px;
  }

  & &-files {
    display: flex;
    flex-direction: column;

    & .eva-file-attach-field-files-file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 5px 0px;

      .closeBtn {
        font-size: 1.2rem;
      }

      .closeBtn:hover {
        cursor: pointer;
      }

      & .eva-file-attach-field-files-file-name {
        display: flex;
        align-items: center;
        width: calc(100% - 65px);
      }
    }

    & .eva-file-attach-field-files-images {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      & .eva-file-attach-field-files-file {
        width: 100px;
        min-height: 76px;
        position: relative;

        & .eva-file-attach-field-files-file-delete-btn {
          position: absolute;
          right: -5px;
          top: -5px;
          border-radius: 50%;
          background-color: #495a6f;
          width: 34px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2rem;
        }

        & .eva-file-attach-field-files-file-delete-btn:hover {
          cursor: pointer;
        }
      }
    }
  }
}
