.eva-table_overflow {
  overflow-x: auto;
  width: 100%;
  min-width: 100%;
}
.eva-table_overflow .eva-repeater__content table {
  width: auto !important;
}
.eva-table_overflow .eva-repeater__content .eva-default-table-cell {
  max-width: 300px;
}
.eva-table {
  height: 100%;
}
.eva-table.eva-repeater--no-padding table {
  padding-left: 0;
  padding-right: 0;
}
.eva-table table {
  width: 100%;
  min-width: 100%;
  border: none;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  /*padding-left: @eva-padding;
    padding-right: @eva-padding;*/
}
.eva-table table tr {
  border: none;
  /*color: #3E4C5D;*/
}
.eva-table table tr.eva-table__row--event {
  /*background-color: #F6F8FF;*/
}
.eva-table table tr.eva-table__row--clickable:hover {
  /*background-color: #E1F5FE;*/
  cursor: pointer;
}
.eva-table table tr.eva-table__row--selected {
  background-color: #0260CF !important;
  color: white!important;
}
.eva-table table tr.eva-table__row--selected .eva-btn:not(.eva-btn--secondary) {
  color: white !important;
}
.eva-table table tr.eva-table__row--selected-alt {
  color: white!important;
}
.eva-table table tr.eva-table__row--selected-alt td {
  border: 3px solid #0260CF !important;
}
.eva-table table tr.eva-table__row--selected-alt .eva-btn:not(.eva-btn--secondary) {
  color: white !important;
}
.eva-table table th {
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 42px;
  border: none;
  /*background-color: white;*/
  white-space: nowrap;
  z-index: 1;
  font-weight: 400;
  font-size: 1.2rem;
}
.eva-table table td {
  font-weight: 400;
}
.eva-table table td,
.eva-table table th {
  text-align: left;
  padding: 2px 8px;
  height: 32px;
  white-space: normal;
  word-break: break-word;
  font-size: 14px;
}
.eva-table table td:first-child,
.eva-table table th:first-child {
  padding-left: 16px;
}
.eva-table table td:last-child,
.eva-table table th:last-child {
  padding-right: 16px;
}
.eva-table table tr:last-child td,
.eva-table table tr:last-child th {
  border-bottom: none!important;
}
.eva-table table .eva-table__header {
  display: flex;
  align-items: center;
  user-select: none;
  min-height: 24px;
  opacity: 0.7;
}
.eva-table table .eva-table__header.eva-table__header--sortable {
  cursor: pointer;
  opacity: 1;
}
.eva-table table .eva-table__header .eva-table__header_sort {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.eva-table table .eva-table__header .eva-table__header_sort_number {
  margin: auto;
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
}
