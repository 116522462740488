.alertContainer {
  display: flex;
  width: 100%;
}
.color-bar {
  width: 2px;
}
.alert-content {
  padding: 5px 0 5px 10px;
}
.purple1.alertContainer {
  background-color: #352B61;
  /* Яркий цвет фона */
}
.purple1 .color-bar {
  background-color: #8323FF;
  /* Цвет левой полосы */
}
.purple1 .alert-content {
  color: #E4E9EF;
  /* Цвет текста на ярком фоне */
}
