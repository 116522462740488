.eva-preview .eva-preview__label {
  font-size: 12px;
  line-height: 1;
}
.eva-preview .eva-preview__value {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.eva-preview .eva-preview__value_header {
  font-size: 16px !important;
}
