
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
//Полупрозрачный фон для хедера
.eva-video__header-background {
  height: 40px;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.3;
}
.eva-video__header {
  height: 40px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;

  //Отображение и выборк ОМ (слева)
  .eva-video__header-left {
    max-width: 65%;
    color: white;

    .v-btn {
      max-width: 100%;

      .v-btn__content {
        width: 100%;
        justify-content: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  //Часы и иконки (справа)
  .eva-video__header-right {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: white;
    z-index: 2;
  }
}
