
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-layout {
  display: flex;
  width: 100%;
  /*height: 100%;*/
  overflow: hidden;
  &.eva-layout--row {
    flex-direction: row;
  }
  &.eva-layout--column {
    flex-direction: column;
  }
  &.eva-layout--fill {
    flex: 1;
  }
  &.eva-layout--no-fill {
    flex: none !important;
  }
  &.eva-layout--gap {
    gap: @eva-padding;
  }
  &.eva-layout--no-shrink {
    flex-shrink: 0;
  }
  &.eva-layout--scroll {
    flex: 1;
    overflow: auto;
  }
  &.eva-layout--scroll-hide {
    flex: 1;
    overflow: hidden;
    &:hover {
      overflow-y: auto;
    }
  }
}
