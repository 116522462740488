
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.alertContainer {
  display: flex;
  width: 100%;
}

.color-bar {
  width: 2px;
}

.alert-content {
  padding: 5px 0 5px 10px;
}

.purple1.alertContainer {
  background-color: #352B61;
  /* Яркий цвет фона */
}

.purple1 .color-bar {
  background-color: #8323FF;
  /* Цвет левой полосы */
}

.purple1 .alert-content {
  color: #E4E9EF;
  /* Цвет текста на ярком фоне */
}
