
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-file-field {
  display: flex;
  flex-direction: column;
  gap: 4px 8px;
  border-top: 2px solid #e5f1ff;
  border-bottom: 2px solid #e5f1ff;
  padding: 16px 0;

  .eva-file-field__header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    .eva-text {
      cursor: pointer;
    }
  }

  .eva-file-field__items {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
