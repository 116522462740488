
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-video-archive2 {
  width: 100%;
  height: 100%;
  display: flex;

  * {
    user-select: none;
  }

  .eva-video-archive__left {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 100%;
    min-height: 0;
    user-select: none;
    .eva-select-date {
      height: @eva-header;
      flex-shrink: 0;
      .eva-textbox__inner {
        display: flex;
        align-items: center;
      }
    }
    .eva-border-bottom-center {
      justify-content: center;
    }
    ul {
      overflow-y: auto;
      list-style-type: none;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 2px 16px;
        height: 32px;

        &:hover {
          opacity: 0.7;
        }

        &::marker {
          display: none;
        }

        &.eva-video-archive--selected {
          border: 2px solid #0260cf !important;
        }
      }
    }
  }
  .eva-video-archive__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    .eva-video-player {
    }
    .eva-video-archive__bottom {
      padding: 6px;
      height: 144px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      position: relative;
      canvas {
        width: 100%;
        height: 24px;
        cursor: pointer;
      }
      .eva-video-archive__current {
        height: 16px;
      }
      .eva-video-archive__map {
        height: 34px;
      }
      .eva-video-archive__range {
        position: absolute;
        top: 30px;
        display: flex;
        align-items: center;
        user-select: none;
        pointer-events: none;
      }

      .eva-video-archive__duration {
        position: absolute;
        top: 4px;
        left: 10px;
        user-select: none;
        pointer-events: none;
        color: #000000;
        font-weight: 600;
      }
      .eva-video-archive__panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: -10px;
        height: 100%;
        width: 100%;
        gap: @eva-padding;
      }
    }
  }
}
