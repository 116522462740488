.eva-catalog-ref-field {
  min-width: 0;
}
.eva-catalog-ref-field .v-menu .v-menu__content {
  max-width: 100%!important;
}
.eva-catalog-ref-field .eva-textbox__input {
  height: unset!important;
  min-height: 100%;
  padding: 3px 0;
  overflow-x: hidden;
}
.eva-catalog-ref-field .eva-textbox__input-inner .eva-catalog-ref-field__list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.eva-catalog-ref-field .eva-textbox__input-inner .eva-catalog-ref-field__list .eva-catalog-ref-field__chip {
  background-color: #0260CF !important;
  color: white !important;
  white-space: normal;
  height: auto;
  min-height: 24px;
  margin: 0!important;
}
.eva-catalog-ref-field .eva-textbox__input-inner .eva-catalog-ref-field__list .eva-catalog-ref-field__chip .v-chip__close {
  margin: 0 !important;
}
.eva-catalog-ref-field .eva-textbox__input-inner .eva-catalog-ref-field__list .eva-catalog-ref-field__chip .v-chip__content {
  gap: 0.75rem;
  width: 100%;
}
.eva-catalog-ref-field .eva-textbox__input-inner .eva-catalog-ref-field__list .eva-catalog-ref-field__chip .v-icon {
  font-size: 16px !important;
}
.eva-catalog-ref-field .eva-textbox__input-inner .eva-catalog-ref-field__list .eva-catalog-ref-field__chip-error {
  border: 2px solid #CC1D26 !important;
}
.eva-catalog-ref-field .eva-textbox__input-inner.eva-textbox__input-inner--no-wrap .eva-catalog-ref-field__list {
  flex-wrap: nowrap;
}
.eva-catalog-ref-field .eva-textbox__input-inner.eva-textbox__input-inner--no-wrap .eva-catalog-ref-field__list .v-chip {
  overflow: unset;
}
.eva-catalog-ref-field .eva-textbox__input-inner.eva-textbox__input-inner--column .eva-catalog-ref-field__list {
  flex-direction: column;
}
.eva-catalog-ref-field .eva-catalog-ref-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  padding: 8px;
}
.eva-catalog-ref-field .eva-catalog-ref__list-item {
  padding-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.eva-catalog-ref-field .eva-catalog-ref__list-item .v-icon {
  font-size: 16px!important;
}
.eva-catalog-ref-field .eva-catalog-ref__list-item:hover {
  background-color: #263444;
}
.eva-catalog-ref-field .eva-catalog-ref__list-item__active {
  background-color: #d2d8dd !important;
}
.eva-catalog-ref-field .eva-catalog-ref__list-item__text-icon {
  margin-left: 10px;
}
.eva-catalog-ref-field .eva-catalog-ref__list-item__text-no-icon {
  margin-left: 26px;
}
.eva-catalog-ref-field .eva-catalog-ref__list-item__actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-right: 8px;
}
.eva-catalog-ref-field .eva-catalog-ref-list-footer {
  display: flex;
  justify-content: center;
}
.eva-catalog-ref-field .v-select__selections input {
  display: none;
}
