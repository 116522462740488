.eva-map-layers-panel {
  left: 175px !important;
}
.eva-map-layers-panel .eva-map-tools-panel__inner {
  padding: 6px;
}
.eva-map-layers-panel .eva-layers-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.eva-map-layers-panel .eva-layers-block .eva-text-clipped {
  min-width: 80px;
}
.eva-map-layers-panel .eva-layers-block.layer-not-selected a,
.eva-map-layers-panel .eva-layers-block.layer-not-selected .eva-layer-icon {
  opacity: 0.5;
}
.eva-map-layers-panel:hover a {
  opacity: 1 !important;
}
