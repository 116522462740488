.eva-jsonarea {
  min-height: 300px;
}
.eva-jsonarea .eva-textbox__inner {
  align-items: unset !important;
  padding-left: 1px;
}
.eva-jsonarea .eva-textbox__input {
  height: unset;
}
.eva-jsonarea.eva-textbox--error .eva-textbox__inner:hover .eva-jsonarea--editor .ace_scroller {
  background-color: #E5F1FF !important;
}
.eva-jsonarea.eva-textbox--error .eva-textbox__inner:hover .eva-jsonarea--editor .ace_gutter {
  background-color: #d2e7ff !important;
}
.eva-jsonarea.eva-textbox--error .eva-textbox__inner:hover .eva-jsonarea--editor .ace_gutter-active-line {
  background-color: #bedcff !important;
}
.eva-jsonarea.eva-textbox--error .eva-jsonarea--editor .ace_scroller {
  background-color: #FFF9F9 !important;
}
.eva-jsonarea.eva-textbox--error .eva-jsonarea--editor .ace_gutter {
  background-color: #ffe5e5 !important;
}
.eva-jsonarea.eva-textbox--error .eva-jsonarea--editor .ace_gutter-active-line {
  background-color: #ffd1d1 !important;
}
.eva-jsonarea .eva-jsonarea--editor {
  height: 100%;
  width: 100%;
  z-index: 1;
}
.eva-jsonarea .eva-jsonarea--editor .ace_scroller {
  background-color: #E5F1FF !important;
}
.eva-jsonarea .eva-jsonarea--editor .ace_gutter {
  background-color: #d2e7ff !important;
}
.eva-jsonarea .eva-jsonarea--editor .ace_gutter-active-line {
  background-color: #bedcff !important;
}
.eva-jsonarea .eva-jsonarea--editor .jsoneditor-menu {
  display: none;
}
.eva-jsonarea .eva-jsonarea--editor .jsoneditor {
  border: none;
}
.eva-jsonarea .eva-jsonarea--editor .jsoneditor-vue {
  height: 100%;
}
.eva-jsonarea .eva-jsonarea--editor .jsoneditor-tree {
  min-height: 100%;
}
.eva-jsonarea .eva-jsonarea--editor .jsoneditor-outer {
  min-height: 100%;
  margin: 0;
  padding: 0;
}
.eva-jsonarea .eva-jsonarea--editor .jsoneditor-readonly {
  display: none;
}
