.eva-video-archive2 {
  width: 100%;
  height: 100%;
  display: flex;
}
.eva-video-archive2 * {
  user-select: none;
}
.eva-video-archive2 .eva-video-archive__left {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 100%;
  min-height: 0;
  user-select: none;
}
.eva-video-archive2 .eva-video-archive__left .eva-select-date {
  height: 50px;
  flex-shrink: 0;
}
.eva-video-archive2 .eva-video-archive__left .eva-select-date .eva-textbox__inner {
  display: flex;
  align-items: center;
}
.eva-video-archive2 .eva-video-archive__left .eva-border-bottom-center {
  justify-content: center;
}
.eva-video-archive2 .eva-video-archive__left ul {
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
}
.eva-video-archive2 .eva-video-archive__left ul li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px 16px;
  height: 32px;
}
.eva-video-archive2 .eva-video-archive__left ul li:hover {
  opacity: 0.7;
}
.eva-video-archive2 .eva-video-archive__left ul li::marker {
  display: none;
}
.eva-video-archive2 .eva-video-archive__left ul li.eva-video-archive--selected {
  border: 2px solid #0260cf !important;
}
.eva-video-archive2 .eva-video-archive__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.eva-video-archive2 .eva-video-archive__content .eva-video-archive__bottom {
  padding: 6px;
  height: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  position: relative;
}
.eva-video-archive2 .eva-video-archive__content .eva-video-archive__bottom canvas {
  width: 100%;
  height: 24px;
  cursor: pointer;
}
.eva-video-archive2 .eva-video-archive__content .eva-video-archive__bottom .eva-video-archive__current {
  height: 16px;
}
.eva-video-archive2 .eva-video-archive__content .eva-video-archive__bottom .eva-video-archive__map {
  height: 34px;
}
.eva-video-archive2 .eva-video-archive__content .eva-video-archive__bottom .eva-video-archive__range {
  position: absolute;
  top: 30px;
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
}
.eva-video-archive2 .eva-video-archive__content .eva-video-archive__bottom .eva-video-archive__duration {
  position: absolute;
  top: 4px;
  left: 10px;
  user-select: none;
  pointer-events: none;
  color: #000000;
  font-weight: 600;
}
.eva-video-archive2 .eva-video-archive__content .eva-video-archive__bottom .eva-video-archive__panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  height: 100%;
  width: 100%;
  gap: 8px;
}
