
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &--column {
    flex-direction: row;
  }

  .eva-tabs__header {
    height: @eva-header;
    width: 100%;
    display: flex;
    flex-direction: row;

    &--column {
      flex-direction: column;

      & .eva-tabs__header-item {
        padding: 29px 20px !important;
      }
    }

    &--border-bottom {
      border-bottom: 1px solid #212d39;
    }

    .eva-tabs__header-item {
      cursor: pointer;
      font-size: 16px;
      display: flex;
      color: #acbbcb;
      align-items: center;
      padding: (@eva-padding * 2);
      border-bottom: 2px solid transparent;

      &:hover {
        border-color: #0260cf50;
      }

      &--column {
        &:hover {
          border-color: #3e4c5d;
        }
      }

      &.eva-tabs__header-item--active {
        border-color: #0094ff;
        color: #ffffff !important;
      }

      &.eva-tabs__header-item--active-column {
        border-color: #3e4c5d;
        color: #ffffff !important;
      }

      &.eva-tabs__header-item--fullsize {
        width: 100%;
        justify-content: center;
      }
    }
  }

  .eva-tabs__content {
    min-height: 0;
    height: 100%;
    width: 100%;
  }
}
