.etv-rowLabel {
  color: #fff;
}
.eva-label {
  color: #7B8B9D;
}
.eva-description {
  color: #E4E9EF;
}
.green-1 {
  color: #3cb17e;
}
.red-1 {
  color: #F44336;
}
