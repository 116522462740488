@import 'variables.less';

.eva-base-field__label {
  font-size: @labelFontSize;
  /*color: @cBlackText;*/
  line-height: 1;
  margin-bottom: @labelIndent;
  display: block;
}

.eva-base-field__inner {
  display: flex;
  padding: @textboxPadding;
  position: relative;
}

.eva-base-field__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: @fieldBgColor;*/
  border-radius: @border-radius;
  transition: all @transition-time;
}

.eva-base-field__field {
  flex-grow: 1;
  width: 100%;
  background: transparent;
  border: 0;
  font-family: inherit;
  -moz-appearance: textfield;
  appearance: textfield;
  outline: none;
  font-size: 14px;
  /*color: @cBlackText;*/
  font-weight: 600;
  padding: 0;
  position: relative;
  z-index: 2;


  &::placeholder {
    font-size: 14px;
    font-family: inherit;
    /*color: @cBlackText;*/
  }
}

.eva-base-field__buttons {
  display: flex;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  //margin-top: 4px;
}

.eva-base-field__button {
  width: 15px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
}

.eva-base-field--error__background {
  /*background: @fieldErrorBgColor;*/
  border: 1px solid @cError !important;
}

.eva-base-field--disabled__background {
  /*background: @cLight;*/
  border: 1px solid @fieldBgColor;
}

.eva-base-field--disabled__buttons {
  opacity: 0.5;
  pointer-events: none;
}


.eva-base-scrollbar {
  /*&::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(2, 96, 207, 0.1);
    opacity: 0.5;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(@cBlackText, 0.5);
    border-radius: 20px;

  }

  scrollbar-width: thin;
  scrollbar-color: rgba(@cBlackText, 0.5) rgba(2, 96, 207, 0.1);*/
}
