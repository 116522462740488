.eva-video-teleport {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.eva-video-teleport .eva-video__main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}
.eva-video-teleport .eva-video__main .eva-video__main-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.eva-video-teleport .eva-video__main .eva-video__main-container .eva-video__header-background {
  height: 40px;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.3;
}
.eva-video-teleport .eva-video__main .eva-video__main-container .eva-video__video {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
}
.eva-video-teleport .eva-video__main .eva-video__main-container .eva-video__error {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.eva-video-teleport .eva-video__main .eva-video__main-container .eva-video__footer {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
}
.eva-video-teleport video::-webkit-media-controls {
  display: none !important;
}
