.eva-video-player {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  position: relative;
}
.eva-video-player__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  position: relative;
}
.eva-video-player__container .eva-video-player__header {
  height: 40px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.eva-video-player__container .eva-video-player__header::after {
  z-index: 2;
}
.eva-video-player__container video {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  /*object-fit: fill;*/
}
.eva-video-player__container .eva-video-player__panel {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 8px;
  z-index: 2;
}
.eva-video-player__container .eva-video__error {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.eva-video-player__container .eva-video__error .eva-icon {
  font-size: 34px;
}
