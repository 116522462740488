.eva-video__header-background {
  height: 40px;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.3;
}
.eva-video__header {
  height: 40px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
}
.eva-video__header .eva-video__header-left {
  max-width: 65%;
  color: white;
}
.eva-video__header .eva-video__header-left .v-btn {
  max-width: 100%;
}
.eva-video__header .eva-video__header-left .v-btn .v-btn__content {
  width: 100%;
  justify-content: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.eva-video__header .eva-video__header-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: white;
  z-index: 2;
}
