
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-text {
  align-items: center;
  margin: auto 0;
  text-transform: none!important;
  &.eva-text--bolder {
    font-weight: bolder;
  }
  &.eva-text--bold {
    font-weight: bold;
  }
  &.eva-text--italic {
    font-style: italic;
  }
  &.eva-text--no-wrap {
    white-space: nowrap;
  }
  &.eva-text--center {
    margin: auto;
  }
  &.eva-text--header {
    /*font-weight: 600;*/
    font-size: 16px;
    align-self: flex-start;
    line-height: 16px;
    /*color: #0260CF;*/
  }
  &.eva-text--subheader {
    font-weight: 400;
    font-size: 14px;
    align-self: flex-start;
    line-height: 14px;
    /*color: #0260CF;*/
  }
  &.eva-text--subheader.eva-text--subheader_right {
    align-self: flex-end !important;
  }
  &.eva-text--subheader.eva-text--subheader_center {
    align-self: flex-end !important;
  }
}
