
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-tree-node {
  width: 100%;
  min-width: fit-content;

  .eva-tree-node__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    height: 40px;
    &:hover {
      /*background-color: #E5F1FF;*/
      cursor: pointer;
    }
    &.eva-tree-node__item--selected {
      color: white;
      background-color: #0260CF!important;
    }
    .eva-tree-node__item-arrow {
      font-size: 18px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      border-radius: 6px;
      border: 1px solid transparent;
      display: flex;
      flex-shrink: 0;
      &:hover {
        /*border: 1px solid #d2e7ff;
        background-color: #E5F1FF;*/
      }
      span {
        margin: auto;
      }
    }
    .eva-tree-node__item-icons {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      .mdi {
        font-size: 18px;
        width: 28px;
        height: 28px;
        display: flex;
        flex-shrink: 0;
      }
      .eva-tree-node__item-icon--disabled {
        opacity: 0.5;
      }
    }
  }
  .eva-tree-node__children {
    padding-left: 16px;
  }

  .textRow {
    white-space: nowrap;
  }
}
