.eva-select-field-popup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 250px;
}
.eva-select-field-popup .eva-btn--item {
  justify-content: start;
  background-color: transparent;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
  transition-duration: 0.4s;
}
.eva-select-field-popup .eva-btn--item:hover {
  opacity: 0.5;
}
.eva-select-field-popup .eva-checkbox {
  height: 27px;
}
