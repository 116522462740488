
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-table_overflow {
  overflow-x: auto;
  width: 100%;
  min-width: 100%;
  
  .eva-repeater__content {
    table {
      width: auto !important;
    }

    .eva-default-table-cell {
      max-width: 300px;
    }
  }
}

.eva-table {
  height: 100%;
  &.eva-table--clickable {

  }
  &.eva-repeater--no-padding {
    table {
      padding-left: 0;
      padding-right: 0;
    }
  }

  table {
    width: 100%;
    min-width: 100%;
    border: none;
    border-spacing: 0;
    border-collapse: separate;
    table-layout: fixed;
    /*padding-left: @eva-padding;
    padding-right: @eva-padding;*/

    tr {
      border: none;
      /*color: #3E4C5D;*/

      &.eva-table__row--event {
        /*background-color: #F6F8FF;*/
      }

      &.eva-table__row--odd {

      }

      &.eva-table__row--clickable:hover {
        /*background-color: #E1F5FE;*/
        cursor: pointer;
      }

      &.eva-table__row--selected {
        background-color: #0260CF !important;
        color: white!important;
        .eva-btn {
          &:not(.eva-btn--secondary) {
            color: white !important;
          }
        }
      }

      &.eva-table__row--selected-alt {
        td {
          border: 3px solid #0260CF !important;
        }
        color: white!important;
        .eva-btn {
          &:not(.eva-btn--secondary) {
            color: white !important;
          }
        }
      }
    }

    th {
      text-align: left;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      height: 42px;
      border: none;
      /*background-color: white;*/
      white-space: nowrap;
      z-index: 1;
      font-weight: 400;
      font-size: 1.2rem;
    }

    td {
      font-weight: 400;
    }

    td, th {
      text-align: left;
      padding: (@eva-padding / 4) @eva-padding;
      height: 32px;
      white-space: normal;
      word-break: break-word;
      font-size: 14px;
    }

    td:first-child, th:first-child {
      padding-left: (@eva-padding * 2);
    }

    td:last-child, th:last-child {
      padding-right: (@eva-padding * 2);
    }

    tr:last-child {
      td, th {
        border-bottom: none!important;
      }
    }

    .eva-table__header {
      display: flex;
      align-items: center;
      user-select: none;
      min-height: 24px;
      opacity: 0.7;

      &.eva-table__header--sortable {
        cursor: pointer;
        opacity: 1;
      }

      .eva-table__header_label {

      }

      .eva-table__header_sort {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .eva-table__header_sort_number {
        margin: auto;
        width: 18px;
        height: 18px;
        text-align: center;
        background-color: rgba(0,0,0,.12);
        border-radius: 50%;
      }
    }
  }
}
