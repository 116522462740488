
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-video-teleport {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  //Главный раздел
  .eva-video__main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;

    //Контейнер с видео
    .eva-video__main-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      //Полупрозрачный фон для хедера
      .eva-video__header-background {
        height: 40px;
        width: 100%;
        position: absolute;
        background-color: black;
        opacity: 0.3;
      }

      .eva-video__video {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
      }

      .eva-video__error {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      //Онлайн футер
      .eva-video__footer {
        height: 50px;
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 @eva-padding;
      }
    }
  }

  video::-webkit-media-controls {
    display: none !important;
  }
}
