
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-map-layers-panel {
  left: 175px !important;
  .eva-map-tools-panel__inner {
    padding: 6px;
  }
  .eva-layers-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;

    .eva-text-clipped {
      min-width: 80px;
    }

    &.layer-not-selected {
      a, .eva-layer-icon {
        opacity: 0.5;
      }
    }
  }
  
  &:hover {
    a {
      opacity: 1 !important;
    }
  }
}
