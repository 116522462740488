
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0;
  /*height: 56px;*/
  &.eva-textbox--readonly {
    .eva-textbox__inner {
      opacity: 0.6;
    }
  }
  > .eva-textbox__inner {
    flex-grow: 1;
    min-height: 40px;
    padding: 1px 12px;
  }
  > .eva-textbox__label {
    margin-bottom: 0;
  }
  &.eva-textbox--preview {
    cursor: default !important;
    & .eva-textbox__inner {
      padding: 0 !important;
      .eva-textbox__background {
        background-color: transparent !important;
      }
      .eva-textbox__input {
        cursor: default !important;
      }
    }
  }
  .eva-textbox__button--error {
    color: #CC1D26;
    &:hover {
      color: #E23244;
    }
  }
  .eva-textbox__button--primary {
    color: #0260CF;
    &:hover {
      color: #006CEB;
    }
  }
  .eva-textbox__background {
    /*color: #3E4C5D;*/
    font-size: 14px;
    font-family: inherit;
    padding: 1px 12px;
    line-height: 26px;
    opacity: 0.8;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
