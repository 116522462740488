.eva-map {
  width: 100%;
  height: 100%;
  position: relative;
  /*background-color: #E5F1FF;*/
}
.eva-map .eva-map-path-panel .eva-map-tools-panel__inner {
  padding: 8px;
}
.eva-map .eva-map__tooltip {
  position: absolute;
  padding: 8px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #BDBDBD;
  background: #BDBDBD;
  color: #3E4C5D;
  box-shadow: 0px 3px 10px 0px #3E4C5D;
  z-index: 99;
}
.eva-map .eva-map_right-panel {
  display: flex;
  flex-direction: column;
}
.eva-map .eva-map__group {
  position: absolute;
  display: flex;
  padding: 12px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #576D88;
  background-color: #3E4C5D;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.26);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 300px;
}
.eva-map .eva-map__group span {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 16px;
  overflow: hidden;
  z-index: 1;
}
.eva-map .eva-map__group span img {
  width: 24px;
  height: 24px;
}
.eva-map .eva-map__group span:hover {
  cursor: pointer;
  outline: 4px solid #ACBBCB;
}
.eva-map .eva-map__group span.eva-map__group--selected {
  outline: 8px solid #ACBBCB;
}
.eva-map .eva-map__canvas {
  width: 100%;
  height: 100%;
}
.eva-map.eva-map--fullscreen {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 199;
}
.eva-map.eva-map--loading .eva-map__canvas {
  opacity: 0;
}
