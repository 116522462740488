.eva-info-text {
  color: @eva-info-color;
}
.eva-success-text {
  color: @eva-success-color;
}
.eva-warning-text {
  color: @eva-warning-color;
}
.eva-error-text {
  color: @eva-error-color;
}

.eva-info-layout {
  background-color: @eva-info-color;
}
.eva-success-layout {
  background-color: @eva-success-color;
}
.eva-warning-layout {
  background-color: @eva-warning-color;
}
.eva-error-layout {
  background-color: @eva-error-color;
}

.eva-text-clipped {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.eva-text-italic {
  font-style: italic;
}
